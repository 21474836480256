<template>
  <div class="boxwrap-header" ref="header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'boxwrap-header',

  inject: {
    boxwrapConfig: {}
  },

  mounted () {
    this.$nextTick(() => {
      if (this.$refs.header) {
        this.boxwrapConfig.header_h = this.$refs.header.offsetHeight
      }
    })
  }

}
</script>
